import type { JSX } from '@builder.io/qwik';
import { component$, useContext } from '@builder.io/qwik';
import { AppDataContext } from '~/contexts/AppDataContext';
import RedditIcon from '~/icons/reddit';
import XIcon from '~/icons/x';
import FacebookIcon from '~/icons/facebook';
import CopyIcon from '~/icons/copy';
import { cva } from 'class-variance-authority';
import { ArticleContext } from '~/contexts/ArticleContext';
import {
  getCSSColorBg,
  getCSSColorBorder,
  getCSSColorFill,
  getCSSColorHoverBg,
} from '~/utilities/getCSS';
import { getHeaderHeight } from '~/utilities/getHeaderHeight';

const cssGroup = cva('', {
  variants: {
    device: {
      mobile: 'block md:hidden',
      laptop: 'hidden md:block',
    },
    orientation: {
      vertical: 'md:mx-4',
      dropdown: '',
      horizontal: '!block',
    },
    keepMobile: {
      true: '',
    },
  },
  compoundVariants: [
    {
      device: 'laptop',
      keepMobile: false,
      class: 'hidden md:block',
    },
  ],
});

const cssWrap = cva('', {
  variants: {
    orientation: {
      vertical: 'sticky top-20 mb-3',
      dropdown: 'absolute left-0 top-10 mb-3',
      horizontal: 'flex gap-4',
    },
  },
});

const cssIconWrap = cva(
  'group flex items-center justify-center rounded-full cursor-pointer',
  {
    variants: {
      background: getCSSColorBg(),
      hoverBackground: getCSSColorHoverBg(),
      border: getCSSColorBorder(),
      style: {
        solid: 'fill-white border-0',
        ghost: 'border-2 bg-white',
      },
      orientation: {
        vertical: 'w-10 h-10 mb-2.5',
        dropdown: 'mb-2.5 w-8 h-8',
        horizontal: 'w-10 h-10',
      },
    },
  }
);

const cssIcon = cva('', {
  variants: {
    style: {
      solid: '',
      ghost: 'group-hover:fill-white',
    },
    iconColor: getCSSColorFill(),
    orientation: {
      vertical: '',
      dropdown: 'w-4 h-4',
      horizontal: '',
    },
  },
});

const icons: Record<string, JSX.ElementType> = {
  reddit: RedditIcon,
  facebook: FacebookIcon,
  twitter: XIcon,
  copy: CopyIcon,
};

const handleCopy = (url: string) => {
  navigator.clipboard
    .writeText(url)
    .then(() => {
      alert('URL copied to clipboard!');
    })
    .catch((err) => {
      console.error('Failed to copy URL: ', err);
    });
};

type ArticleShareType = {
  orientation?: 'vertical' | 'horizontal' | 'dropdown';
  hasPrint?: boolean;
  iconVariant?: 'solid' | 'ghost';
  device?: 'mobile' | 'laptop';
  class?: string;
  keepMobile?: boolean;
};

// TODO: Implement print button
export default component$<ArticleShareType>(
  ({
    orientation,
    hasPrint = false,
    iconVariant,
    device,
    class: className,
    keepMobile = false,
  }): JSX.Element => {
    const {
      payloadToSSR: {
        layout,
        canonicalURL,
        pageContent: { pageSettings },
      },
      themeComponents,
    } = useContext(AppDataContext);

    const article = useContext(ArticleContext);

    if (!article) return <></>;

    const iconWrapClasses = cssIconWrap({
      style: iconVariant ?? 'solid',
      orientation: orientation ?? 'vertical',
      background:
        iconVariant === 'ghost'
          ? 'white'
          : themeComponents.verticalShareMenu.backgroundColor.value,
      hoverBackground: themeComponents.verticalShareMenu.backgroundColor.value,
      border: themeComponents.verticalShareMenu.backgroundColor.value,
    });

    const iconClasses = cssIcon({
      style: iconVariant ?? 'solid',
      orientation: orientation ?? 'vertical',

      // Fansided sites use solid background with white icons, while other sites use ghost background with colored icons
      iconColor:
        iconVariant === 'solid'
          ? 'white'
          : themeComponents.socialButtons.strokeColor.value,
    });

    const articleUrl = article.articleUrl
      ? article.articleUrl
      : canonicalURL
        ? canonicalURL
        : '';

    return (
      <div
        data-testid='share-menu'
        class={cssGroup({
          device: device ?? 'laptop',
          class: className,
          orientation: orientation ?? 'vertical',
          keepMobile,
        })}
      >
        <div
          data-testid='share-menu-inner'
          class={cssWrap({
            orientation: orientation ?? 'vertical',
          })}
          style={{
            top:
              device === 'mobile'
                ? '125%'
                : getHeaderHeight(themeComponents, layout, { add: 20 }),
          }}
        >
          {article.finiteScrollNumber === 0 &&
            pageSettings?.shareConfig?.value &&
            pageSettings.shareConfig.value.map((shareInfo, index) => {
              const { alt, url } = shareInfo;
              const IconComponent = icons[alt];

              if (!IconComponent) return null;

              return (
                <a
                  key={index}
                  class={iconWrapClasses}
                  href={url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <IconComponent
                    cssClasses={iconClasses}
                    iconVariant={iconVariant}
                  />
                </a>
              );
            })}
          <button
            class={iconWrapClasses}
            onClick$={() => handleCopy(articleUrl)}
            aria-label='copy'
            data-url={articleUrl}
          >
            <CopyIcon cssClasses={iconClasses} />
          </button>
          {hasPrint && (
            <button class={iconWrapClasses} type='button' aria-label='print'>
              <svg
                class={iconClasses}
                width='22'
                height='22'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M5 8a1 1 0 100 2 1 1 0 000-2zm12-4h-1V1a1 1 0 00-1-1H5a1 1 0 00-1 1v3H3a3 3 0 00-3 3v6a3 3 0 003 3h1v3a1 1 0 001 1h10a1 1 0 001-1v-3h1a3 3 0 003-3V7a3 3 0 00-3-3zM6 2h8v2H6V2zm8 16H6v-4h8v4zm4-5a1 1 0 01-1 1h-1v-1a1 1 0 00-1-1H5a1 1 0 00-1 1v1H3a1 1 0 01-1-1V7a1 1 0 011-1h14a1 1 0 011 1v6z' />
              </svg>
            </button>
          )}
        </div>
      </div>
    );
  }
);
