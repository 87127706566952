import type { AuthorInterface } from '~/types/AppData/PageContent/AuthorInterface';

/**
 * Extracts X information from an author's social links.
 *
 * @param author - The author object containing social links.
 * @returns An object with the X link and username, or null if not found or invalid.
 *
 * The function searches for a social link with an `alt` property of "twitter" (case-insensitive).
 * If a valid Twitter URL is found, it extracts the username from the URL pathname.
 */
export const extractAuthorXInfo = (author: AuthorInterface) => {
  const x = author.authorInfo.socialLinks?.value.find(
    ({ alt }) => alt.toLowerCase() === 'twitter'
  );
  if (!x?.url) return null;

  try {
    const url = new URL(x.url);
    const username = url.pathname.split('/')[1] || null;
    return (
      username && {
        link: x.url,
        username,
      }
    );
  } catch {
    return null;
  }
};
