import type { JSX } from '@builder.io/qwik';
import { component$ } from '@builder.io/qwik';

export default component$(
  ({ cssClasses }: { cssClasses: string }): JSX.Element => {
    return (
      <svg
        class={cssClasses}
        width='16'
        height='16'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 64 64'
      >
        <title>Copy Article</title>
        <g>
          <path d='M4.3,49.8c-0.9,0-1.8-0.3-2.6-0.9C0.6,48.1,0,46.8,0,45.5L0,4.3C0,1.9,1.9,0,4.3,0l41.2,0c2.4,0,4.3,1.9,4.3,4.3l0,6.5 l-7.1,0l0-3.7l-35.6,0l0,35.5l3.8,0l0,7.1l-6.3,0C4.5,49.8,4.4,49.8,4.3,49.8z' />
          <path d='M18.6,64c-2.4,0-4.3-1.9-4.3-4.3l0-41.1c0-2.4,1.9-4.3,4.3-4.3l41,0c2.4,0,4.3,1.9,4.3,4.3L64,59.7c0,2.4-1.9,4.3-4.3,4.3 L18.6,64z M21.3,21.3l0,35.5l35.5,0l-0.1-35.5L21.3,21.3z M56.8,18.5C56.8,18.5,56.8,18.5,56.8,18.5L56.8,18.5z' />
        </g>
      </svg>
    );
  }
);
