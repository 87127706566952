import type { AuthorInterface } from '~/types/AppData/PageContent/AuthorInterface';

/**
 * Retrieves a list of article authors based on the provided owner and additional authors.
 *
 * @param authors - An object containing the owner and additional authors.
 * @param authors.owner - The primary author of the article.
 * @param authors.additional - An optional array of additional authors.
 * @param hideOwner - A boolean flag indicating whether to include the owner in the result.
 * @returns An array of authors, including the owner (if not hidden) and any additional authors.
 */
export const getArticleAuthors = (
  authors?: { owner?: AuthorInterface; additional?: AuthorInterface[] },
  hideOwner?: boolean
): AuthorInterface[] => {
  let a = [];

  if (authors?.owner && !hideOwner) {
    a.push(authors.owner);
  }

  if (authors?.additional) {
    a = [...a, ...authors.additional];
  }

  return a;
};
