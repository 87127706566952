import type { JSX } from '@builder.io/qwik';
import { component$ } from '@builder.io/qwik';
import Image from '../Image/Image';
import type { AuthorImageType } from '~/types/AppData/PageContent/AuthorInterface';
import { cva } from 'class-variance-authority';

interface AuthorImageProps {
  image: AuthorImageType;
  isRounded?: boolean;
  widthClass?: 'authorBio';
}

const cssClasses = cva('!bg-[transparent]', {
  variants: {
    imageWidths: {
      default: 'w-8 h-8 mr-2.5',
      authorBio: 'w-[70px] h-[70px]',
    },
  },
});

export default component$(
  ({ image, isRounded, widthClass }: AuthorImageProps): JSX.Element => {
    const { caption, path, aspectRatio, fileExtension } = image;
    let imageWidths = { small: 80, medium: 80, large: 80 };
    if (typeof aspectRatio === 'object') {
      imageWidths = {
        small: aspectRatio.x || 80,
        medium: aspectRatio.x || 80,
        large: aspectRatio.x || 80,
      };
    }

    return (
      <Image
        aspectRatio={'1/1'}
        imageWidths={imageWidths}
        loading='lazy'
        imageClass={isRounded ? 'rounded-full' : ''}
        alt={caption}
        path={path}
        fileExtension={fileExtension}
        wrapperClass={cssClasses({ imageWidths: widthClass || 'default' })}
      />
    );
  }
);
