import type { JSX } from '@builder.io/qwik';
import { component$, useContext } from '@builder.io/qwik';
import { cva } from 'class-variance-authority';
import XIcon from '~/icons/x';
import { AppDataContext } from '~/contexts/AppDataContext';
import { ArticleContext } from '~/contexts/ArticleContext';
import AuthorImage from '~/components/Shared/AuthorImage/AuthorImage';
import { getArticleAuthors } from '~/utilities/getArticleAuthors';
import { getCSSColorText } from '~/utilities/getCSS';
import { extractAuthorXInfo } from '~/utilities/extractAuthorXInfo';
import { formatDateForDisplay } from '~/utilities/formatDateForDisplay';
import { useIsPremiumTemplate } from '~/utilities/useIsPremiumTemplate';
import ArticleShare from '../ArticleShare/ArticleShare';

const authorNameClasses = cva('font-group-tiny underline mb-1', {
  variants: {
    color: getCSSColorText(),
  },
});

export default component$((): JSX.Element => {
  const {
    payloadToSSR: {
      pageContent: { pageSettings },
    },
    themeComponents: { authorBio },
  } = useContext(AppDataContext);
  const article = useContext(ArticleContext);
  if (!article) return <></>;

  const isPremium = useIsPremiumTemplate();

  const authors = getArticleAuthors(
    article.authors,
    article.metadata?.hideOwner
  );
  const showUpdatedAt =
    pageSettings?.shouldDisplayUpdatedAtDate?.value ||
    article.createdAt !== article.updatedAt;

  return (
    <div class='flex flex-col gap-5 my-[30px]'>
      <hr class='border-borderGrey' />
      <div>
        <span class='font-group-tiny text-grey'>
          {'Published '}
          <time dateTime={article.createdAt}>
            {formatDateForDisplay(article.createdAt || '')}
          </time>
        </span>
        {showUpdatedAt && (
          <span class='font-group-tiny text-grey'>
            {' | Modified '}
            <time dateTime={article.updatedAt}>
              {formatDateForDisplay(article.updatedAt || '')}
            </time>
          </span>
        )}
      </div>
      {authors.map((each, index) => {
        const xInfo = extractAuthorXInfo(each);
        return (
          <div key={index} class='flex gap-4 ml-3'>
            <AuthorImage
              widthClass='authorBio'
              image={{
                ...each.authorInfo.image.value,
                aspectRatio: { x: 180, y: 180 },
              }}
              isRounded
            />
            <div class='flex flex-col flex-1'>
              <a
                data-testtype='author-link'
                href={each.pagePath}
                class={authorNameClasses({
                  color: authorBio.linkInTextColor.value,
                })}
              >
                {each.name?.toUpperCase()}
              </a>
              <p
                data-testtype='author-bio'
                class='font-group-big line-clamp-3 overflow-hidden text-ellipsis mb-4'
              >
                {each.authorInfo.bio?.value}
              </p>
              {xInfo && (
                <a
                  data-testtype='x-link'
                  class='flex items-center gap-2 w-fit h-9 rounded-2xl bg-darkGrey hover:bg-primary cursor-pointer px-5 py-2.5'
                  href={xInfo.link}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <XIcon cssClasses='fill-white' />
                  <span class='font-group-tiny text-white'>{`Follow ${xInfo.username}`}</span>
                </a>
              )}
            </div>
          </div>
        );
      })}
      {!isPremium && (
        <>
          <ArticleShare
            orientation='horizontal'
            hasPrint
            iconVariant='ghost'
            class='!mx-0'
          />
          <hr class='border-borderGrey' />
        </>
      )}
    </div>
  );
});
