import { format as timeagoFormat } from 'timeago.js';

/**
 * Formats a date based on whether it is within the last 24 hours or older.
 * - For dates within the last 24 hours, returns a relative time (e.g., "5 hours ago").
 * - For dates older than 24 hours, returns the full formatted date (e.g., "Jan 1, 2021").
 *
 * @param isoDate - The date to format, provided as a string in ISO format (e.g., '2001-01-01T21:21:21Z').
 * @param locale - The locale to use for formatting the full date (default: 'en-US').
 * @returns A string representing the formatted date or relative time.
 */
export const formatDateForDisplay = (
  isoDate: string,
  locale = 'en-US'
): string => {
  const now = new Date();
  const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
  const date = new Date(isoDate);

  if (date < twentyFourHoursAgo) {
    return date.toLocaleDateString(locale, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  } else {
    return timeagoFormat(date, locale);
  }
};
